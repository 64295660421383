import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout.jsx'
import Container from '../components/Container'
import Center from '../components/Center'
import Link from '../components/Link'

export default () => (
  <Layout>
    <Helmet>
      <title>Ошибка 404</title>
    </Helmet>
    <Container>
      <Center>
        <h1 style={{ textAlign: 'center' }}>Ошибка 404</h1>
        <p>Скорее всего эта страница никогда не существовала, либо стала ненужной и мы её грохнули.</p>
        <p>Так бывает, не расстраивайтесь. <Link to="/">На главной</Link> есть ещё страницы, не хуже этой.</p>
        <p>Жаловаться сюда → <Link simple to="mailto:1admin@ukr.net">1admin@ukr.net</Link></p>
      </Center>
    </Container>
  </Layout>
)
