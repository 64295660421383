import React from 'react'
import styles from './Center.module.css'

export default ({ children }) => {
  return (
    <div className={styles['Center']}>
      <div className={styles['Center__Inner']}>
        {children}
      </div>
    </div>
  )
}